@import "../../index.css";

.containerStyles {
  background-color: var(--green-400);
  margin-bottom: var(--s-17);
  padding-top: var(--s-15);
  padding-bottom: var(--s-9);
  width: 100%;

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: var(--s-24);
    padding-bottom: var(--s-24);
  }
}

.wrapperStyles {
  width: 100%;

  @media (min-width: 768px) {
    width: 75%;
  }
}

.carouselContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: var(--s-5);
  padding-top: 0;
  background-color: var(--green-400);
  min-width: 100%;

  @media (min-width: 768px) {
    margin-top: var(--s-10);
    padding-bottom: 0;
  }

  @media (min-width: 1280px) {
    padding-right: 0;
    border-radius: var(--s-2);
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: var(--s-10);
    padding-top: var(--s-3);
  }
}

.carouselStyles {
  display: block;
  width: 100%;
  margin-left: var(--s-4);
  margin-right: var(--s-4);

  @media (min-width: 768px) {
    width: 100%;
    margin: 0;
  }
}

.bodyStyles {
  display: block;
  width: 100%;

  @media (min-width: 768px) {
    width: 88%;
  }
}

.divider {
  margin-right: var(--s-4);
  margin-left: var(--s-4);
  margin-bottom: var(--s-4);
  background-color: var(--gray-600);
  height: 1px;
  border: none;

  @media (min-width: 768px) {
    margin: 0;
  }
}

.dividerTwo {
  background-color: var(--gray-500);
  height: 1px;
  margin-top: var(--s-10);
  border: none;
}

.titleStyles {
  display: flex;
  font-size: var(--text-3xl);
  font-family: var(--font-base);
  font-weight: var(--font-bolder);
  color: var(--gray-900);
  margin-bottom: var(--s-3);
  padding-left: var(--s-4);

  @media (min-width: 768px) {
    margin-bottom: var(--s-5);
    font-size: var(--text-4xl);
    padding-left: 0 !important;
  }

  @media (min-width: 1024px) {
    font-size: var(--text-5xl);
  }

  @media (min-width: 1280px) {
    margin-bottom: var(--s-7);
    font-size: var(--text-6xl);
  }
}

.subtitleStyles {
  display: flex;
  font-size: var(--text-sm);
  font-family: var(--font-base);
  font-weight: var(--font-normal);
  color: var(--gray-700);
  margin-bottom: var(--s-3);
  padding-left: var(--s-4);

  @media (min-width: 768px) {
    font-size: var(--text-base);
    padding-left: 0;
  }
}

.serviceStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--text-xl);
  font-family: var(--font-base);
  font-weight: var(--font-bold);
  color: var(--gray-900);
  margin-bottom: var(--s-3);
  margin-top: var(--s-7);

  @media (min-width: 1280px) {
    color: white;
  }
}
