@import "../../index.css";

.containerStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--gray-400);
  margin-bottom: var(--s-17);
  padding-top: var(--s-17);
  padding-bottom: var(--s-17);

  @media (min-width: 768px) {
    margin-bottom: var(--s-20);
    padding-top: var(--s-24);
    padding-bottom: var(--s-24);
  }
}

.wrapperStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.bodyStyles {
  display: block;
  width: 100%;

  @media (min-width: 768px) {
    width: 75%;
  }
}

.carousel {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.carouselWrapper {
  background-color: transparent;
  max-width: 78%;
  flex-shrink: 0;
  margin-right: var(--s-4);
}

.carouselWrapper:last-child {
  margin-right: 0;
}

.carouselTestimonialContainer {
  background-color: var(--white);
  padding: var(--s-4);
}


.carouselContainer {
  display: flex;
  padding-left: var(--s-4);
  padding-right: var(--s-4);

  @media (min-width: 1024px) {
    display: none;
  }
}

.marqueeWrapper {
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
}

.titleStyles {
  display: flex;
  font-size: var(--text-3xl);
  font-family: var(--font-base);
  font-weight: var(--font-bolder);
  color: var(--gray-900);
  margin-bottom: var(--s-3);
  padding-left: var(--s-4);

  @media (min-width: 768px) {
    padding-left: 0 !important;
    margin-bottom: var(--s-6);
    font-size: var(--text-6xl);
  }
}

.subtitleStyles {
  display: flex;
  font-size: var(--text-base);
  font-family: var(--font-base);
  font-weight: var(--font-normal);
  color: var(--gray-700);
  margin-bottom: var(--s-3);
  padding-left: var(--s-4);

  @media (min-width: 768px) {
    padding-left: 0;
  }
}

.divider {
  margin-right: var(--s-4);
  margin-bottom: var(--s-6);
  margin-left: var(--s-4);
  background-color: var(--gray-500);
  height: 1px;
  border: none;

  @media (min-width: 768px) {
    margin: 0;
  }
}

.testimonialContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: var(--s-5);

  @media (min-width: 768px) {
    margin-top: var(--s-10);
  }
}

.testimonialWrapper {
  background-color: white;
  padding: var(--s-4);
  width: var(--s-65);
  margin-bottom: 0;
  margin-right: var(--s-4);
  margin-left: var(--s-4);

  @media (min-width: 768px) {
    width: var(--s-100);
    padding: var(--s-10);
    margin-right: var(--s-10);
    margin-left: var(--s-10);
    margin-bottom: var(--s-10);
  }
}

.testimonialBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: var(--s-3);
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.04);
    text-decoration: underline;
  }
}

.testimonialBio {
  text-decoration: underline;
  text-align: left;
}

.testimonialName {
  display: flex;
  font-size: var(--text-lg);
  font-family: var(--font-base);
  font-weight: var(--font-medium);
  color: var(--gray-900);
  margin-bottom: var(--s-2);
}

.testimonialJonTitle {
  display: flex;
  font-size: var(--text-sm);
  font-family: var(--font-base);
  color: var(--gray-700);
  margin-bottom: var(--s-2);
}

.sectionImage {
  display: flex;
  overflow: hidden;
  max-width: var(--s-17);
  max-height: var(--s-17);
  object-fit: cover;
  border-radius: 50%;
  margin-right: 20px;
  margin-left: 10px;

  @media (min-width: 1024px) {
    width: var(--s-20);
    height: var(--s-20);
  }
}

.testimonialReferral {
  font-size: var(--text-sm);
  font-family: var(--font-base);
  color: var(--gray-700);
  font-style: italic;
  text-align: left;
}

.quoteIconStyles {
  display: none;

  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
    margin-right: var(--s-4);
    margin-left: auto;
    align-items: center;
  }
}
