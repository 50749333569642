@import "../../index.css";

.containerStyles {
  display: block;
  background-color: var(--green-400);
  margin-bottom: var(--s-17);
  padding-top: var(--s-14);
  padding-bottom: var(--s-10);

  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--green-400);
    margin-bottom: var(--s-10);
    padding-top: var(--s-24);
    padding-bottom: var(--s-24);
  }
}

.wrapperStyles {
  display: block;
  width: 100%;

  @media (min-width: 768px) {
    width: 95%;
  }

  @media (min-width: 1024px) {
    width: 80%;
  }

  @media (min-width: 1280px) {
    width: 75%;
  }
}

.bodyStyles {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
}

.headingStyles {
  margin-bottom: var(--s-10);
  width: 100%;

  @media (min-width: 768px) {
    margin-bottom: var(--s-20);
  }
}

.titleStyles {
  font-size: var(--text-3xl);
  font-family: var(--font-base);
  font-weight: var(--font-bolder);
  color: var(--gray-900);
  margin-bottom: var(--s-5);
  padding-left: var(--s-4);

  @media (min-width: 768px) {
    font-size: var(--text-6xl);
    padding-left: 0;
  }
}

.subtitleStyles {
  font-size: var(--text-base);
  font-family: var(--font-base);
  font-weight: var(--font-normal);
  color: var(--gray-700);
  margin-bottom: var(--s-4);
  padding-left: var(--s-4);
  padding-right: var(--s-4);

  @media (min-width: 768px) {
    font-size: var(--text-lg);
    padding-left: 0;
    padding-right: 0;
  }
}

.divider {
  background-color: var(--gray-600);
  height: 1px;
  border: none;
  margin-left: var(--s-4);
  margin-right: var(--s-4);

  @media (min-width: 768px) {
    margin-left: 0;
    margin-right: 0;
  }
}

.sectionStyles {
  font-family: var(--font-base);
  margin-left: var(--s-4);
  margin-bottom: var(--s-6);

  @media (min-width: 768px) {
    margin-left: 0;
    margin-bottom: 0;
  }
}

.subheadingStyles {
  font-size: var(--text-sm);
  font-family: var(--font-base);
  font-weight: var(--font-extra-light);
  color: var(--gray-900);
  margin-bottom: var(--s-2);
  margin-top: var(--s-2);

  @media (min-width: 768px) {
    margin-bottom: var(--s-5);
    margin-top: var(--s-4);
  }
}

.contactStyles {
  font-size: var(--text-base);
  font-family: var(--font-base);
  font-weight: var(--font-light);
  color: var(--gray-900);
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
  
  @media (min-width: 1024px) {
    font-size: var(--text-xl);
  }
}
