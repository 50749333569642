@import "../../index.css";

.pageWrapper {
  margin-left: 0;
  margin-right: 0;

  @media (min-width: 768px) {
    margin-left: var(--s-7);
    margin-right: var(--s-7);
  }
}

.navbarWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.desktopStyles {
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.mobileStyles {
  display: block;
  width: 100%;
  box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.05);

  @media (min-width: 1024px) {
    display: none;
  }
}

.mobileMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-base);
  font-size: var(--text-lg);
  color: var(--gray-900);
  font-weight: var(--font-medium);
  margin-top: var(--s-50);
}

.headerWrapper {
  height: 90vh;
  width: 100%;

  @media (min-width: 1024px) {
    margin-bottom: var(--s-20);
    height: 83vh;
  }
}
