@import "../../index.css";

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.partialMessage {
  font-family: var(--font-base);
  font-size: var(--text-base);
  color: var(--white);
  white-space: pre-wrap;
}

.botMessageWrapper .partialMessage {
  font-family: var(--font-base);
  font-size: var(--text-base);
  color: var(--white);
}

.botMessageWrapper .blinkingCursor {
  display: inline-block;
  width: 2px;
  background-color: var(--white);
  margin-left: 2px;
  animation: blink 1s step-end infinite;
}

.containerStyles {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--gray-1000);
  height: 100%;
  padding-top: var(--s-72);
  padding-right: var(--s-5);
  padding-left: var(--s-5);

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: var(--s-72);
    padding-right: 0;
    padding-left: 0;
  }
}

.containerStylesAtTop {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--gray-1000);
  height: 100%;
  padding-right: var(--s-5);
  padding-left: var(--s-5);
  padding-top: var(--s-20);

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
  }
}

.messagesWrapper {
  margin: 0;
  max-width: 600px;
  min-width: 90vw;
  padding: 0;
  border-radius: var(--s-4);
  color: var(--white);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: var(--s-3);
  flex-grow: 1;

  @media (min-width: 768px) {
    padding: var(--s-3);
    margin: var(--s-6) auto;
    max-width: 600px;
    min-width: 600px;
  }
}

.userMessage {
  align-self: flex-end;
  text-align: right;
  font-family: var(--font-base);
  background-color: var(--gray-1100);
  color: var(--white);
  padding: var(--s-5);
  border-radius: var(--s-4);
  width: fit-content;
  margin: var(--s-2) 0;
}

.botMessage {
  align-self: flex-start;
  text-align: left;
  font-family: var(--font-base);
  color: var(--white);
  padding: var(--s-5) var(--s-5) var(--s-5) 0;
  border-radius: var(--s-4);
  width: fit-content;
  margin: var(--s-2) 0;

  @media (min-width: 768px) {
    padding: var(--s-5);
  }
}

.botMessageWrapper {
  display: flex;
  align-items: flex-start;
  gap: var(--s-3);
}

.botIconStyles {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border: 1px solid var(--gray-700);
  border-radius: 50%;
  padding: var(--s-2);
  margin-top: -10px;
}

.headerStyles {
  display: flex;
  justify-content: center;
  width: 100%;
  color: var(--white);
  font-family: var(--font-base);
  font-size: var(--text-3xl);
  font-weight: 600;
}

.textBoxStyles {
  width: 600px;
  padding: var(--s-5);
  font-size: var(--text-base);
  border-radius: var(--s-4) 0 0 var(--s-4);
  border: none;
  background-color: var(--gray-1100);
  color: var(--white-200);
}

.textBoxStyles::placeholder {
  color: var(--white-100);
  border: none;
}

.textBoxStyles:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.inputStyles {
  display: flex;
  justify-content: center;
  border: none;
  width: 100%;
  margin-top: var(--s-6);
  margin-bottom: var(--s-6);
  position: static;
}

.inputStylesAtBottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--gray-1000);
  padding: var(--s-4);
  z-index: 10;
}

.spinnerStyles {
  width: 24px;
  height: 24px;
  margin: auto;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--gray-1100);
  border-radius: 0 var(--s-4) var(--s-4) 0;
  padding: var(--s-3);
  cursor: pointer;
}

.iconStyles {
  min-width: 100%;
  min-height: 100%;
}

.inputWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.sendButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.sendButton:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.responseStyles {
  font-family: var(--font-base);
  color: var(--white-200);
  width: 700px;
  padding-left: var(--s-3);
}

.errorStyles {
  margin-top: 20px;
  color: #dc3545;
}
