@import "../../index.css";

.containerStyles {
  width: 100%;
  height: 100%;
  background-color: var(--gray-400);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.wrapperStyles {
  font-family: var(--font-base);
  width: 100%;
  height: 100%;

  @media (min-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
  }
}

.contentStyles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: var(--s-7);
  width: 100%;
  height: 38vh;

  @media (min-width: 768px) {
    width: 60%;
    height: auto;
  }
}

.titleStyles {
  font-size: var(--text-3xl);
  font-weight: var(--font-bolder);
  margin-bottom: var(--s-3);
  padding-left: var(--s-4);

  @media (min-width: 768px) {
    font-size: var(--text-5xl);
    margin-bottom: var(--s-8);
  }

  @media (min-width: 1280px) {
    padding-left: 0;
    font-size: var(--text-6xl);
  }
}

.subTitleStyles {
  font-size: var(--text-lg);
  margin-bottom: var(--s-5);
  padding-left: var(--s-4);

  @media (min-width: 768px) {
    margin-bottom: var(--s-8);
    font-size: var(--text-2xl);
  }

  @media (min-width: 1280px) {
    padding-left: 0;
    font-size: var(--text-3xl);
  }
}

.buttonWrapper {
  padding-left: var(--s-4);
  padding-bottom: var(--s-5);

  @media (min-width: 768px) {
    padding-bottom: 0;
  }

  @media (min-width: 1280px) {
    padding-left: 0;
    font-size: var(--text-3xl);
  }
}

.contactButton {
  font-size: var(--text-lg);
  font-weight: var(--font-light);
  padding: var(--s-4) var(--s-7);
}

.imageContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  margin-right: 0;

  @media (min-width: 1024px) {
    position: relative;
    width: 40%;
    height: 100%;
  }

  @media (min-width: 1280px) {
    width: 40%;
  }
}

.imageWrapper {
  width: 100%;
  height: var(--s-112);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;

  @media (min-width: 768px) {
    height: var(--s-165);
  }
}

.headerImage {
  width: 445px;
  height: 445px;
  object-fit: contain;

  @media (min-width: 768px) {
    width: 600px;
    height: 600px;
  }
}
