@import "../../index.css";

.wrapperStyles {
  display: flex;
  justify-content: center;
  padding-top: 0;
  margin-bottom: var(--s-17);
  width: 100%;

  @media (min-width: 768px) {
    padding-bottom: var(--s-10);
    margin-top: var(--s-10);
    margin-bottom: var(--s-10);
  }
}

.containerStyles {
  display: block;
  text-align: center;
  width: 100%;

  @media (min-width: 768px) {
    width: var(--s-151);
  }
}

.titleStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-bottom: var(--s-7);
  font-size: var(--text-3xl);
  font-family: var(--font-base);
  font-weight: bold;

  @media (min-width: 768px) {
    font-size: var(--text-5xl);
    margin-bottom: var(--s-10);
  }
}

.carouselWrapper {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.skillWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: var(--text-lg);
  font-family: var(--font-base);
  margin-right: 2rem;

  @media (min-width: 768px) {
    font-size: var(--text-2xl);
  }
}

.iconStyles {
  width: var(--s-11);
  height: var(--s-11);
  margin-bottom: var(--s-2);

  @media (min-width: 768px) {
    width: var(--s-14);
    height: var(--s-14);
    margin-bottom: var(--s-4);
  }
}
