@import "../../index.css";

.containerStyles {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapperStyles {
  display: block;
  width: 100%;

  @media (min-width: 1024px) {
    width: 85%;
  }

  @media (min-width: 1280px) {
    width: 75%;
  }
}

.topSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: var(--s-1);
  padding-bottom: var(--s-4);

  @media (min-width: 1024px) {
    padding-top: var(--s-7);
    flex-direction: row;
    justify-content: space-between;
  }
}
.divider {
  background-color: var(--gray-500);
  height: 1px;
  border: none;
}

.leftSideStyles {
  flex: 1;
}

.rightSideStyles {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--s-2);
  width: 100%;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: flex-end;
    width: auto;
    gap: var(--s-5);
  }
}

.logoWrapper {
  text-align: left;
  margin-bottom: var(--s-11);

  @media (min-width: 1024px) {
    margin-bottom: 0;
  }
}

.logoWrapper {
  display: flex;
  align-items: center;
  gap: var(--s-2);
}

.logoStyles {
  height: var(--s-8);
  width: var(--s-8);
}

.iconWrapper {
  display: block;
  height: auto;
  width: auto;
}

.nameWrapper {
  font-family: var(--font-base);
  font-weight: var(--font-medium);
  font-size: var(--text-xl);
}

.titleWrapper {
  font-family: var(--font-base);
  font-size: var(--text-xs);
  color: var(--gray-800);
  font-weight: var(--font-light);
}

.testimonialsContainer,
.testimonialWrapper {
  text-align: left;
}

.testimonialStyles {
  font-family: var(--font-base);
  font-size: var(--text-sm);
  cursor: pointer;
  color: var(--gray-700);
  font-weight: var(--font-light);
  margin-bottom: var(--s-2);
  white-space: nowrap;

  &:hover {
    color: var(--gray-900);
  }
}

.contactValueStyles {
  font-family: var(--font-base);
  font-size: var(--text-sm);
  color: var(--gray-800);
  font-weight: normal;
  margin-bottom: var(--s-2);
}

.bottomSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: var(--s-5);
  margin-bottom: var(--s-5);
  gap: var(--s-5);

  @media (min-width: 1024px) {
    margin-top: var(--s-5);
    margin-bottom: var(--s-9);
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
  }
}

.navItemStyles {
  font-family: var(--font-base);
  font-size: var(--text-sm);
  color: var(--gray-700);
  white-space: nowrap;
  cursor: pointer;
}

.copyIconStyles {
  margin-top: 5px;
  margin-right: 2px;
}

.copyStyles {
  font-family: var(--font-base);
  font-size: var(--text-xs);
  color: var(--gray-700);
  font-weight: var(--font-bold);
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 1024px) {
    font-size: var(--text-sm);
  }
}

.iconSection {
  display: flex;
  justify-content: center;
  gap: var(--s-2);
  margin-top: var(--s-9);

  @media (min-width: 1024px) {
    margin-left: auto;
    margin-top: 0;
  }
}

.iconStyles {
  display: flex;
  align-items: center;
  cursor: pointer;
}
