@import "../../index.css";

.navbarContainer {
  width: 75%;
}

.topSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: var(--s-7);
  padding-bottom: var(--s-4);
}

.divider {
  background-color: var(--gray-500);
  height: 1px;
  border: none;
}

.leftSideStyles {
  flex: 1;
}

.rightSideStyles {
  display: flex;
  justify-content: flex-end;
  gap: var(--s-5);
  flex: 1;
}

.logoWrapper {
  display: flex;
  align-items: center;
  gap: var(--s-2);
  width: var(--s-56);
  text-align: left;
  cursor: pointer;
}

.logoStyles {
  height: var(--s-8);
  width: var(--s-8);
}

.iconWrapper {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.nameWrapper {
  font-family: var(--font-base);
  font-weight: var(--font-medium);
  font-size: var(--text-xl);
}

.titleWrapper {
  font-family: var(--font-base);
  font-size: var(--text-xs);
  color: var(--gray-800);
  font-weight: var(--font-light);
}

.emailWrapper,
.numberWrapper {
  text-align: left;
}

.contactTitleStyles {
  font-family: var(--font-base);
  font-size: var(--text-sm);
  color: var(--gray-700);
  font-weight: var(--font-extra-light);
  margin-bottom: var(--s-2);
}

.contactValueStyles {
  font-family: var(--font-base);
  font-size: var(--text-sm);
  color: var(--gray-800);
  font-weight: normal;
  margin-bottom: var(--s-2);
  cursor: pointer;
  
  &:hover {
    text-decoration: underline;
  }
}

.bottomSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: var(--s-5);
  margin-bottom: var(--s-9);
}

.navItemStyles {
  font-family: var(--font-base);
  font-size: var(--text-sm);
  color: var(--gray-700);
  white-space: nowrap;
  cursor: pointer;
  font-weight: var(--font-light);

  &:hover {
    color: var(--gray-900);
  }
}

.navbarStyles {
  display: flex;
  align-items: center;
  gap: var(--s-10);
}

.iconSection {
  display: flex;
  align-items: center;
  gap: var(--s-2);
  margin-left: auto;
}

.iconStyles {
  display: flex;
  align-items: center;
  cursor: pointer;
}
