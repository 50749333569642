@import "../../index.css";

.pageStyles {
  height: 90vh;
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    height: 100vh;
  }
}

.pageWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;
}

.navbarWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.desktopStyles {
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.mobileStyles {
  display: block;
  width: 100%;
  box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.05);

  @media (min-width: 1024px) {
    display: none;
  }
}

.chatWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
