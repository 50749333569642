.carousel {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mediaWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.slide {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding: var(--s-4);
  background-color: var(--green-900);
  border-radius: var(--s-2);
  margin-bottom: var(--s-6);

  @media (min-width: 768px) {
    flex: 50%;
    margin: var(--s-2);
  }

  @media (min-width: 1024px) {
    flex: 30%;
  }
}

.slide img,
.slide video {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.titleStyles {
  margin-bottom: var(--s-3);
  color: white;
  font-family: var(--font-base);
  font-size: var(--text-lg);
  font-weight: bold;
  text-align: center;
}

.description {
  color: white;
  font-family: var(--font-base);
  font-size: var(--text-sm);
  text-align: left;
  margin-top: var(--s-2);
}

.buttonWrapper {
  display: flex;
  align-self: flex-start;
  margin-top: auto;
}

.buttonStyles {
  margin-top: var(--s-2);
  padding: var(--s-4) var(--s-5) var(--s-4) var(--s-5);
  background-color: var(--gray-900);
  color: white;
  text-align: center;
  cursor: pointer;
  border-radius: var(--s-2);

  &:hover {
    transform: scale(1.05);
  }
}
