@import "../../index.css";

.containerStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--s-17);
  padding-top: var(--s-17);

  @media (min-width: 768px) {
    padding-top: 0;
    margin-bottom: var(--s-15);
  }
}

.wrapperStyles {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  gap: var(--s-6);

  @media (min-width: 1024px) {
    flex-direction: row;
    padding: var(--s-10);
    width: 75%;
  }
}

.sectionImage {
  width: 100%;
  max-width: var(--s-65);
  height: auto;

  @media (min-width: 768px) {
    max-width: var(--s-80);
  }

  @media (min-width: 1024px) {
    max-width: var(--s-100);
  }
}

.boxStylesOne {
  display: none;

  @media (min-width: 768px) {
    display: block;
    background-color: var(--green-400);
    margin-top: var(--s-10);
    min-width: var(--s-20);
    min-height: var(--s-80);
  }
}

.boxStylesTwo {
  display: none;

  @media (min-width: 768px) {
    display: block;
    background-color: var(--green-400);
    margin-top: var(--s-10);
    min-width: var(--s-6);
    min-height: var(--s-80);
  }
}

.columnOne,
.columnTwo {
  flex-basis: 50%;
}

.columnOne {
  display: none;

  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.columnTwo {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: flex-start;
  padding-left: var(--s-4);
  padding-right: var(--s-4);
  height: var(--s-140);
}

.titleStyles {
  font-size: var(--text-2xl);
  font-family: var(--font-base);
  font-weight: var(--font-bolder);
  margin-top: var(--s-1);
  color: var(--gray-900);
  max-width: var(--s-100);
  width: 100%;

  @media (min-width: 1024px) {
    font-size: var(--text-5xl);
    margin-top: var(--s-5);
  }

  @media (min-width: 1280px) {
    font-size: var(--text-6xl);
  }
}

.subtitleStyles {
  font-size: var(--text-base);
  font-family: var(--font-base);
  font-weight: var(--font-light);
  margin-top: var(--s-3);
  margin-bottom: var(--s-3);
  color: var(--grey-900);
  max-width: var(--s-100);
  width: 100%;

  @media (min-width: 768px) {
    font-size: var(--text-lg);
    margin-top: var(--s-5);
    margin-bottom: var(--s-5);
  }
}

.bodyOneStyles {
  font-size: var(--text-sm);
  font-family: var(--font-base);
  color: var(--gray-700);
  width: 100%;
  margin-bottom: var(--s-4);

  @media (min-width: 768px) {
    font-size: var(--text-base);
    margin-bottom: var(--s-5);
  }
}

.sectionStyles {
  font-size: var(--text-sm);
  font-family: var(--font-base);
  display: flex;
  gap: var(--s-5);
  margin-bottom: var(--s-4);
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: 768px) {
    font-size: var(--text-base);
    align-items: left;
    flex-direction: unset;
    margin-bottom: var(--s-5);
  }
}

.iconStyles {
  width: var(--s-4);
  height: var(--s-4);
  margin-right: var(--s-2);
}

.sectionHeaderStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--font-bold);
  color: var(--gray-900);
  white-space: nowrap;
}

.sectionBodyStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--gray-900);
  font-weight: var(--font-extra-light);
  white-space: nowrap;
}

.buttonStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: var(--s-32);
  min-height: var(--s-14);
  background-color: var(--green-400);
  color: var(--gray-900);
  font-size: var(--text-base);
  font-family: var(--font-base);
  font-weight: var(--font-bold);
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}
